@import "vendors/font-awesome.scss";
@import "vendors/icofont.scss";
@import "vendors/themify/_themify-icons.scss";
@import "vendors/flag-icon.scss";
@import "vendors/feather-icon/feather-icon";
@import "vendors/whether-icon.scss";
@import "vendors/scrollbar.scss";
@import "vendors/animate.scss";
@import "vendors/prism.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "style";
@import "responsive";
@import "color-1.scss";
@import "vendors/dropzone.scss";
@import "./vendors/image-cropper.scss";
// @import "vendors/owlcarousel.scss";
// @import "vendors/icofont.scss";
@import "vendors/chartist.scss";

.vld-overlay.is-full-page {
  z-index: 10000 !important;
}

.underline {
  text-decoration: underline !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-link.active span {
  color: #000080 !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-link.active span:hover {
  color: #000080 !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-submenu li a.active {
  color: #000080 !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-submenu li a.active:hover {
  color: #000080 !important;
}

.sidebar-title {
  color: #000080 !important;
}

.c-text {
  color: #000080 !important;
}

// .active{
//   color: #000080 !important;
// }

.page-link.active {
  color: #000080 !important;
}

.page-wrapper.compact-wrapper .page-header {
  margin-left: 0px !important;
  width: 100% !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  top: 91px !important;
  background-color: #FFFFFF !important;
  box-shadow: none !important;
}

.page-header {
  height: 91px;
  background-image: linear-gradient(to right, #000080 0%, #5858FF 100%);
}

.page-wrapper .page-body-wrapper {
  background-color: #FFFFFF !important;
}

.page-body {
  background-color: #FFFFFF !important;
}

.top-elipse {
  position: absolute;
  z-index: -1;
  height: 700px;
  width: 100%;
  background: radial-gradient(circle at top left, rgba(97, 206, 255, 0.5), rgba(97, 206, 255, 0), #fff);
}

.bottom-elipse {
  position: absolute;
  bottom: -70px;
  right: 0;
  height: 600px;
  width: 100%;
  background: radial-gradient(circle at bottom right, rgba(0, 0, 128, 1) -30%, #fff, #fff);
}

.header-heading {
  font-family: 'Rubik';
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
}

.header-notify-text {
  font-family: 'Rubik';
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
}

.notification-icon {
  width: 25px !important;
  height: 25px !important;
  stroke: #FFFFFF !important;
}

.page-wrapper .page-header .header-wrapper .nav-right>ul>li .badge {
  right: -20px !important;
  top: -20px !important;
  padding: 6px 9px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family: 'Poppins' !important;
  color: black;
}

.admin-dropdown {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #FFFFFF !important;
}

.hp-logo {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.c-text-area {
  height: 1000px !important;
}

.form-control {
  border-radius: 1px !important;
  background-color: #F4F8F9;
  height: 42px !important;
  color: black !important;
  border: 1px solid #E4EDF0 !important;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #3535CD !important;
}

.form-control::placeholder {
  color: #A4A4A4;
}

.c-form-select {
  width: 100%;
  border-radius: 1px !important;
  background-color: #F4F8F9;
  height: 42px !important;
  color: black !important;
  border: 1px solid #E4EDF0 !important;
}

.c-form-select:focus {
  box-shadow: none !important;
  border: 1px solid #3535CD !important;
}

.c-form-select::placeholder {
  color: #A4A4A4;
}

.text-area {
  border-radius: 1px !important;
  background-color: #F4F8F9;
  color: black !important;
  border: 1px solid #E4EDF0 !important;
  width: 100%;
}

.text-area:focus {
  box-shadow: none !important;
  border: 1px solid #3535CD !important;
}

.text-area::placeholder {
  color: #A4A4A4;
}

/* Hide the default checkbox */
.c-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border: 2px solid #000080;
  /* Border color */
  border-radius: 2px;
  cursor: pointer;
}

.card {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1) !important;
}

/* Style the checkmark */
.c-checkbox::before {
  content: '\2713';
  /* Unicode character for a checkmark */
  display: inline-block;
  width: 25px;
  /* Adjust size if needed */
  height: 25px;
  /* Adjust size if needed */
  text-align: center;
  line-height: 25px;
  font-size: 16px;
  /* Adjust size if needed */
  color: transparent;
  /* Transparent color for the checkmark */
}

/* Display the checkmark when the checkbox is checked */
.c-checkbox:checked::before {
  color: #000080;
  /* Color of the checkmark */
}

.c-button {
  padding: 10px 35px;
  border-radius: 25px;
  border: none;
}

.c-button:focus {
  outline: none;
}

.c-button:hover {
  color: white;
}

.radio-button {
  border: 1px solid #000080;
  padding: 10px 20px;
  margin: 5px;
  background-color: #E5E5F2;
  cursor: pointer;
  border-radius: 5px;
  color: #000080;
}

.radio-button.selected {
  background-color: #000080 !important;
  color: white;
}

.cbg-primary {
  color: #fff;
  background-color: #000080;
}

.cbg-danger {
  color: #fff;
  background-color: #FF3364;
}

.cbg-secondary {
  color: #fff;
  background-color: #949494;
}

.footer {
  padding: 0 !important;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border: 1px solid #e6e0e0;
  padding: 10px;
  border-radius: 5px;
}

.tag {
  background-color: #0910a3;
  color: white;
  padding: 5px 10px;
  border-radius: 25px;
}

.tag .remove-tag {
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
  color: red;
}

.cursor {
  cursor: pointer;
}

.dateclass {
  width: 100%;
}

.dateclass::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.dateclass.placeholderclass::before {
  color: #A4A4A4;
  width: 100%;
  content: attr(placeholder);
}

.dateclass.placeholderclass:hover::before {
  color: #A4A4A4;
  // width: 0%;
  // content: "";
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  color: #A4A4A4 !important;
}