.login-card {
    background: none !important;
}

.login-card .login-main {
    /* width: 100% !important; */
    padding: 10px;
    box-shadow: none !important;
    background: transparent !important;
}

/* Apply Bootstrap form control styles */
.c-form-control {
    display: block;
    width: 100%;
    height: 60px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #d1d1ec;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid rgb(161, 184, 207) !important;
    border-radius: 12px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Add focus style */
.c-form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ced4da;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.c-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #888;
}

.c-btn {
    height: 70px;
    background: #000080 !important;
    border-radius: 12px;
    color: #fff;
    border: none;
}

.c-checkbox {
    border: 2px solid #000080;
}

.link {
    color: #000080 !important;
}

.c-top-elipse {
    position: absolute;
    z-index: -1;
    height: 600px;
    width: 50% !important;
    background: radial-gradient(circle at top left, rgba(97, 206, 255, 0.5), rgba(97, 206, 255, 0), #fff);
}

.c-bottom-elipse {
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    height: 600px;
    width: 50% !important;
    background: radial-gradient(circle at bottom right, rgba(0, 0, 128, 1) -30%, rgba(0, 0, 128, 0), #fff);
}